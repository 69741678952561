import React, { Fragment } from "react";
import { findDOMNode } from "react-dom";
import TweenOne from "rc-tween-one";
import { Menu, notification, Icon } from "antd";
import tryNow from "../../img/homeYMYT/trynow-icon.png";
import tryNowfff from "../../img/homeYMYT/trynow-icon-fff.png";
import serviceCall from "../../img/homeYMYT/serviceCall-icon.png";
import serviceCallfff from "../../img/homeYMYT/serviceCall-icon-fff.png";
import wechart from "../../img/homeYMYT/wechart-icon.png";
import wechartfff from "../../img/homeYMYT/wechart-icon-fff.png";
import wechartQrcode from "../../img/homeYMYT/wechatQrcode.jpg";
import wechartQrcodeFFF from "../../img/homeYMYT/wechatQrcodeFFF.png";
import serviceTell from "../../img/homeYMYT/serciveTell.png";

const Item = Menu.Item;
const SubMenu = Menu.SubMenu;
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: false,
      menuHeight: 0,
      selected: ["0"],
      qrcodeShow: false
    };
  }

  componentWillMount = () => {
    this.changeHash();
    window.addEventListener("hashchange", () => {
      this.changeHash();
    });
  };

  changeHash = () => {
    let hash = window.location.hash;
    if (hash === "#/product") {
      this.setState({
        selected: ["1"]
      });
    } else if (hash === "#/solution/customer") {
      this.setState({
        selected: ["customer"]
      });
    } else if (hash === "#/solution/forwarder") {
      this.setState({
        selected: ["forwarder"]
      });
    } else if (hash === "#/solution/park") {
      this.setState({
        selected: ["park"]
      });
    } else if (hash === "#/product/TMS") {
      this.setState({
        selected: ["TMS"]
      });
    } else if (hash === "#/support/contraband") {
      this.setState({
        selected: ["contraband"]
      });
    } else if (hash === "#/about") {
      this.setState({
        selected: ["3"]
      });
    } else if (hash === "#/join") {
      this.setState({
        selected: ["4"]
      });
    } else {
      this.setState({
        selected: ["0"]
      });
    }
  };

  phoneClick = () => {
    const menu = findDOMNode(this.menu);
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
      menuHeight: phoneOpen ? menu.scrollHeight : 0
    });
  };

  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;
    const { menuHeight, phoneOpen, qrcodeShow } = this.state;
    const navData = dataSource.Menu.children;
    const navChildren = Object.keys(navData).map((key, i) => {
      if (navData[key].a.children === "解决方案") {
        return (
          <SubMenu
            title={<span className="submenu-title-wrapper">解决方案</span>}
            key="solution"
          >
            <Item key="customer">
              <a href="#/solution/customer">货主端</a>
            </Item>
            <Item key="forwarder">
              <a href="#/solution/forwarder">第三方物流</a>
            </Item>
            <Item key="park">
              <a href="#/solution/park">物流园区</a>
            </Item>
          </SubMenu>
        );
      }
      if (navData[key].a.children === "产品服务") {
        return (
          <SubMenu
            title={<span className="submenu-title-wrapper">产品服务</span>}
            key="product"
          >
            <Item key="TMS">
              <a href="#/product/TMS">E-TMS</a>
            </Item>
            {/* <Item key="OMS">
              <a href="#/product/OMS">E-OMS</a>
            </Item>
            <Item key="WMS">
              <a href="#/product/WMS">E-WMS</a>
            </Item>
            <Item key="boost">
              <a href="#/product/boost">物流助推</a>
            </Item>
            <Item key="ymyt">
              <a href="#/product/ymyt">一码运通</a>
            </Item> */}
          </SubMenu>
        );
      }
      if (navData[key].a.children === "帮助与支持") {
        return (
          <SubMenu
            title={<span className="submenu-title-wrapper">帮助与支持</span>}
            key="support"
          >
            {/* <Item key="learn">
              <a href="#/support/learn">了解一码运通</a>
            </Item> */}
            {/* <Item key="contraband">
              <a href="#/support/contraband">违禁运物品查询</a>
            </Item> */}
            <Item key="goodsSearch">
              <a href="https://search.e-yuntong.com" target="_blank">
                货物查询
              </a>
            </Item>
          </SubMenu>
        );
      }
      return (
        <Item key={i.toString()} {...navData[key]}>
          <a
            {...navData[key].a}
            href={navData[key].a.href}
            target={navData[key].a.target}
          >
            {navData[key].a.children}
          </a>
        </Item>
      );
    });
    const openNotification = () => {
      const args = {
        message: "400-698-5656",
        description: "详情请拨打电话 400-698-5656,欢迎来电咨询",
        duration: 3,
        icon: <Icon type="phone" style={{ color: "#108ee9" }} />
      };
      notification.open(args);
    };
    return (
      <Fragment>
        <TweenOne
          component="header"
          animation={{ opacity: 0, type: "from" }}
          {...dataSource.wrapper}
          {...props}
        >
          <div
            {...dataSource.page}
            className={`${dataSource.page.className}${
              phoneOpen ? " open" : ""
            }`}
          >
            <TweenOne
              animation={{ x: -30, type: "from", ease: "easeOutQuad" }}
              {...dataSource.logo}
            >
              <img width="100%" src={dataSource.logo.children} alt="img" />
            </TweenOne>
            <TweenOne
              {...dataSource.Menu}
              animation={{ x: 30, type: "from", ease: "easeOutQuad" }}
              ref={c => {
                this.menu = c;
              }}
              style={isMobile ? { height: menuHeight } : { float: "right" }}
            >
              <Menu
                mode={isMobile ? "inline" : "horizontal"}
                theme={isMobile ? "dark" : "default"}
                selectedKeys={this.state.selected}
              >
                {navChildren}
              </Menu>
            </TweenOne>
          </div>
        </TweenOne>
        <div
          className="tip-modal tip1"
          onClick={() => {
            window.open("http://oms.ymyt56.com", "target", "");
          }}
        >
          <div>
            <img src={tryNowfff} alt="立即试用" className="isTryNow" />
            <img src={tryNow} alt="立即试用" className="notTryNow" />
            <p
              style={{
                color: "rgb(255, 255, 255)"
              }}
            >
              立即试用
            </p>
          </div>
        </div>
        <div className="tip-modal tip2">
          <div className="tip2-modal-hover">
            <a href="tel://4006985656" onClick={openNotification}>
              <img
                src={serviceCall}
                alt="热线电话：4006985656"
                className="notServiceCall"
              />
              <img
                src={serviceCallfff}
                alt="热线电话：4006985656"
                className="isServiceCall"
              />
              <p
                style={{
                  color: "rgb(255, 255, 255)"
                }}
              >
                客服热线
              </p>
            </a>
          </div>
          <p className="tellShow">
            <img src={serviceTell} />
          </p>
        </div>
        <div className="tip-modal tip3">
          <img src={wechartfff} alt="公众号" className="isWechart" />
          <img src={wechart} alt="公众号" className="notWechart" />
          <p
            style={{
              color: "rgb(255, 255, 255)"
            }}
          >
            公众号
          </p>
          <img src={wechartQrcodeFFF} className="wechart-hover-describe" />
        </div>
      </Fragment>
    );
  }
}

export default Header;
