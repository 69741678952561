import React, { Component } from "react";
import { Row, Col } from "antd";
import customer1 from "../../img/Home/1.png";
import customer2 from "../../img/Home/2.png";
import customer3 from "../../img/Home/3.png";
import customer4 from "../../img/Home/4.png";
import "./less/content50.less";
class Content50 extends React.PureComponent {
  state = {
    news: [
      "欢迎 天津宝德贵泰物流有限公司 入驻一码运通ETMS系统",
      "欢迎 丹佛斯（天津）有限公司 入驻一码运通OMS系统",
      "欢迎 天津中邮物流有限公司 入驻一码运通ETMS系统",
      "欢迎 天津天士力医药商业有限公司 入驻一码运通OMS系统",
      "欢迎 北京保龙达物流有限公司 入驻一码运通ETMS系统",
      "欢迎 天津鑫德邦运输有限公司 入驻一码运通ETMS系统",
      "欢迎 辽宁天地胜业互联网物流有限公司 入驻一码运通ETMS系统",
      "欢迎 深圳市广华达物流有限公司 入驻一码运通ETMS系统",
      "欢迎 广州倍格生物科技有限公司 入驻一码运通OMS系统",
      "欢迎 天津大无缝物流发展有限公司 入驻一码运通ETMS系统",
      "欢迎 烟台金利达物流园 入驻一码运通ETMS系统",
      "欢迎 忠旺铝业有限公司 入驻一码运通OMS系统",
      "欢迎 天津市吉万运输有限公司一码运通ETMS系统",
      "欢迎 尼德克有限公司一码运通OMS系统"
    ]
  };
  componentDidMount() {
    this.scrollNews();
  }
  scrollNews = () => {
    var box = document.getElementById("box");
    var con1 = document.getElementById("con1");
    var con2 = document.getElementById("con2");
    var s = document.getElementsByTagName("a");
    var speed = 50;
    con2.innerHTML = con1.innerHTML;
    this.timer = setInterval(() => {
      if (box.scrollTop >= con1.scrollHeight) {
        box.scrollTop = 0;
      } else box.scrollTop++;
    }, speed);
  };
  render() {
    return (
      <div className="customer-container">
        {/* <div className="Tenants-container">
          <p>
            天津贵泰物流加入 丹佛斯（天津）加入 中邮加入 天津天士力医药加入
            北京保龙达物流加入 天津鑫德邦运输加入 辽宁天地胜业加入
            深圳市广华达物流加入
          </p>
          <p>
            广州倍格生物科技有限公司加入 天津大无缝物流加入 烟台金利达物流园加入
          </p>
        </div> */}
        <div className="Tenants-container">
          <div id="box">
            <ul id="con1">
              {this.state.news.map((item, index) => (
                <li key={index}>
                  <a href="#">{item}</a>
                </li>
              ))}
            </ul>
            <ul id="con2"></ul>
          </div>
        </div>
        <div className="customer-content">
          <span className="customer-title" id="partner">
            合作伙伴
          </span>
          <Row style={{ marginTop: "40px" }}>
            <Col
              className="customer-item-box"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div className="customer-item">
                <img className="customer-img" src={customer1} />
              </div>
            </Col>
            <Col
              className="customer-item-box"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div className="customer-item">
                <img className="customer-img" src={customer2} />
              </div>
            </Col>
            <Col
              className="customer-item-box"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div className="customer-item">
                <img className="customer-img" src={customer3} />
              </div>
            </Col>
            <Col
              className="customer-item-box"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div className="customer-item">
                <img className="customer-img" src={customer4} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Content50;
